<template>
  <div>
    <headerTab></headerTab>
    <div class="deal_freigth">
      <div class="freight_title">经系统检测，转让完毕后，将为您制作如下合同</div>
      <div class="freight_list">1.居间协议</div>
      <div class="freight_list" v-if="queryList.status==1">2. 股份受让协议</div>
      <div class="freight_list" v-if="queryList.status==2&&tableData1.user.shares">2.股份受让协议</div>
      <div class="freight_list" >3.转让申请书</div>
      
      <div class="freight_list" v-if="tableData1.union_shareholder==0">4.销售劳务合同</div>
      <div class="freight_list1">请时刻留意物料信息，收货后请妥善保管</div>
    </div>
    <div class="freight_price">运费金额:<span>￥{{queryList.freight}}</span></div>
    <div class="pay_status">请选择支付方式</div>
    <div class="seclet">
      <van-radio-group v-model="checked">
        <van-radio name="1" icon-size="20px"><span class="pay_text">微信支付</span></van-radio>
      </van-radio-group>
      <img src="../../assets/wxpay.png" />
    </div>
    <div class="text_tent">
      <span>*说明:</span>若本次转让的过程中，工分合同剩余工分等于<span>0</span>,回寄的工分<span>合同将会被回收</span>,当工分合同转让后存在剩<span style="color:#0F87FF">余工分，方产出一份出让版工分赠与合同。</span>
    </div>
    <div class="infoBtn" @click="submit">提交</div>
  </div>
</template>
<script>
import { payfreight ,sellerfreight,dealDetails_api} from "../../api/deal";
import headerTab from "../../components/headerTab.vue";
import * as apiCommon from "@/api/common";
import wx from "weixin-js-sdk";
import { Toast } from "vant";
export default {
  data() {
    return {
      showPicker: false,
      imgWidth: 645,
      signtureShow: false,
      img: "",
      imgConfig: "{ useCdnDomain: true }",
      iconShow: false,
      checked: "1",
      queryList: "",
      payShow: true,
      tableData: "",
      tableData1:""
    };
  },
  components: {
    headerTab
  },
  created() {
    document.title = "运费支付";
    this.queryList = this.$route.query;
    this.getHome();
  },
  methods: {
    getHome() {
      dealDetails_api().then(res => {
        console.log(res);
        if (res.code == 0) {
          this.tableData1 = res.data;
        }
      });
    },
    goClone() {
      this.$router.go(-1);
    },
    mineMessage() {},
    async submit() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true
      });
      let res1 = await apiCommon.getConfig();
      let tableData = res1.data;
      wx.config(tableData);

      if (res1.code == 0) {
        if (this.payShow == false) {
          return false;
        }
        this.payShow = false;
        if (this.queryList.status == 2) {
          sellerfreight(this.queryList.id).then(res => {
            if (res.code == 0) {
              this.payShow = true;
              let that = this;
              wx.chooseWXPay({
                timestamp: res.data.sales_pay_info.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                nonceStr: res.data.sales_pay_info.nonceStr, // 支付签名随机串，不长于 32 位
                package: res.data.sales_pay_info.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                signType: "MD5", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                paySign: res.data.sales_pay_info.paySign, // 支付签名
                success(res) {
                  if (res.errMsg == "chooseWXPay:ok") {
                    that.$toast("支付成功");
                    that.$router.go(-1);
                  } else {
                    alert(res.errMsg);
                    that.$toast("支付失败", "middle");
                  }
                },
                cancel: function(res) {
                  that.$toast("支付取消", "middle", res);
                }
              });
            }
          });
        } else {
          payfreight(this.queryList.id).then(res => {
            if (res.code == 0) {
              this.payShow = true;
              let that = this;
              wx.chooseWXPay({
                timestamp: res.data.trade_pay_info.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                nonceStr: res.data.trade_pay_info.nonceStr, // 支付签名随机串，不长于 32 位
                package: res.data.trade_pay_info.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                signType: "MD5", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                paySign: res.data.trade_pay_info.paySign, // 支付签名
                success(res) {
                  if (res.errMsg == "chooseWXPay:ok") {
                    that.$toast("支付成功");
                    that.$router.go(-1);
                  } else {
                    alert(res.errMsg);
                    that.$toast("支付失败", "middle");
                  }
                },
                cancel: function(res) {
                  that.$toast("支付取消", "middle", res);
                }
              });
            }
          });
        }
      }
    }
  }
};
</script>
<style>
.van-toast {
  width: 300px;
  min-height: 120px;
}
.van-toast__text {
  font-size: 35px;
}
.van-icon {
  font-size: 40px;
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
.header_contract {
  display: flex;
  justify-content: space-between;
  margin-left: 41px;
  /* margin-top: 10px; */
}
.header_contract div {
}
.header_contract img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.contranct_text1 {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000;
  margin-top: 20px;
}
.messageTitle3 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle3 {
  text-align: right;
  margin-right: 20px;
  font-size: 24px;
  margin-top: 20px;
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 20px;
  color: #333;
}
.userTitle {
  color: #0f87ff;
  font-size: 24px;
  letter-spacing: 2.5px;
  line-height: 32px;
}
.confirm_content {
  width: 632px;
  margin: 136px auto 42px;
}
.userTitle span {
  color: #ff3e46;
}
.confirm_list {
  display: flex;
  font-size: 30px;
  color: #1c1b1b;
  margin-bottom: 30px;
}
.confirm_list .title {
  width: 150px;
  text-align: right;
}
.form_group {
  margin-top: 34px;
}
.userDetails {
  text-align: center;
  font-size: 24px;
  margin-top: 30px;
}

.signtureShow {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.signtureShow_box {
  width: 80%;
  background-color: #fff;
  margin: 150px auto 0;
  border-radius: 15px;
  padding-bottom: 20px;
}

.ruleBtn1 {
  color: #6d6d6d;
  margin-left: 20px;
  font-size: 25px;
}
.select_box {
  width: 32px;
  height: 32px;
  border: 1px solid #deb77a;
  opacity: 1;
  border-radius: 0px;
  margin-left: 100px;
}
.office_submit {
  width: 206px;
  height: 68px;
  margin: 10px auto 0;
}
.office_submit img {
  width: 100%;
  height: 100%;
}
.infoBtn {
  width: 388px;
  height: 90px;
  background: #deb77a;
  border-radius: 50px;
  text-align: center;
  line-height: 90px;
  font-size: 35px;
  color: #fff;
  margin: 100px auto 30px;
}
.deal_freigth {
  width: 648px;
  margin: 20px auto;
}

.freight_title {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #0f87ff;
  margin-top: 100px;
  margin-bottom: 45px;
}
.freight_list {
  font-size: 30px;
  color: #232323;
  margin-bottom: 24px;
  margin-left: 30px;
}
.freight_list1 {
  font-size: 25px;
  color: #ff3e46;
  margin-bottom: 24px;
  margin-left: 30px;
  margin-bottom: 88px;
}
.freight_price {
  font-size: 30px;
  color: #585858;
  text-align: right;
  margin-right: 59px;
}
.freight_price span {
  font-size: 40px;
  color: #ff3e46;
}
.pay_status {
  font-size: 30px;
  color: #ababab;
  width: 630px;
  border-bottom: 2px solid #ececec;
  opacity: 1;
  padding-bottom: 30px;
  margin-left: 60px;
  margin-top: 80px;
}
.seclet {
  margin: 42px 0 0 82px;
  display: flex;
}
.pay_text {
  font-size: 30px;
  margin-left: 30px;
  margin-bottom: 20px;
  margin-bottom: 10px;
}
.seclet img {
  width: 35px;
  height: 30px;
  margin: 10px 0 0 10px;
}
.text_tent {
  font-size: 25px;
  color: #1c1b1b;
  width: 650px;
  margin: 56px auto;
  letter-spacing: 3px;
  line-height: 30px;
}
.text_tent span {
  color: #ff3e46;
}
</style>
